import React from 'react';
import '../../assets/top.css'
import { Carousel } from 'antd';

class home extends React.Component {
    constructor(props) {
      super(props);
      this.state={
        menuClass1:'header-menu-slotline',
        menuClass2:'header-menu-slotline',
        menuClass3:'header-menu-slotline',
      }
    }

    //菜单打开
    listDown = () =>{
      let menuLists = document.getElementsByClassName('header-menu-lists')[0];
      menuLists.style.transform ='translate(0,0)';
      this.setState({menuClass1:'header-menu-slotline2'})
      this.setState({menuClass2:'header-menu-slotline4'})
      this.setState({menuClass3:'header-menu-slotline3'})
      document.documentElement.style.overflow='hidden';//电脑端禁止滑动
      document.body.style.overflow='hidden';//手机端禁止滑动
    }
    //菜单关闭
    listUp = () =>{
        let menuLists = document.getElementsByClassName('header-menu-lists')[0];
        menuLists.style.transform ='translate(0,-100%)'    
        this.setState({menuClass1:'header-menu-slotline'})
        this.setState({menuClass2:'header-menu-slotline'})
        this.setState({menuClass3:'header-menu-slotline'})
        document.documentElement.style.overflow='auto';//电脑端可以滑动
        document.body.style.overflow='auto';//手机端可以滑动
    }
    //菜单拉伸
    openMenu = () =>{
        if(this.state.menuClass1==='header-menu-slotline'){
            this.listDown()
        }else{
            this.listUp()
        }
    }

    //关闭通知
    closeAlert = () =>{
      document.getElementsByClassName("alert")[0].style.display = "none"
    }

    render(){
      return (
        <div className='top'>

          {/*
          Alert
          <div className='alert' onClick={this.closeAlert}>
            <div className='alert-box'> 
              <div className='alert-box-title'>
                店舗移転のお知らせ
              </div>
              <div className='alert-box-content'>
                いつもAnnへご来店いただき、誠にありがとうございます。 <br/>
                10月1日より東京都中央区銀座8-6-5 （ジーエスエイトビルディング1F）へ店舗を移転する運びとなりました。新店舗オープンに伴いキャンペーンも行う予定でございます。また、店舗では高級な設計士により設計しており、個室も充実しています。<br/>
                今後とも変わらぬご愛顧を賜りますようお願い申し上げます。
              </div>
            </div>
          </div>

          <div className='notify'>
            <div className='notify-title'>
              店舗移転のお知らせ
            </div>
            <div className='notify-content'>
                いつもAnnへご来店いただき、誠にありがとうございます。 <br/>
                10月1日より東京都中央区銀座8-6-5 （ジーエスエイトビルディング1F）へ店舗を移転する運びとなりました。新店舗オープンに伴いキャンペーンも行う予定でございます。また、店舗では高級な設計士により設計しており、個室も充実しています。<br/>
                今後とも変わらぬご愛顧を賜りますようお願い申し上げます。
            </div>
          </div>
          */}

          {/*HEAD*/}
          <div className='header-menu-lists'>
            <a onClick={this.listUp} className='header-menu-list' href="#room">部屋</a>
            <a onClick={this.listUp} className='header-menu-list' href="#cast">チャスト</a>
            <a onClick={this.listUp} className='header-menu-list' href="#system">料金</a>
            <a onClick={this.listUp} className='header-menu-list' href="#information">アクセス</a>
          </div>
          <div className='top-head'>
            <div className='top-head-left'>
              ANNCLUB
            </div>
            <div className='top-head-right'>
              <a href="#room">部屋</a>
              <a href="#cast">チャスト</a>
              <a href="#system">料金</a>
              <a href="#information">アクセス</a>
            </div>
            <div className='top-head-right-iphone'>
              <div className='header-menu' onClick={this.openMenu}>
                <div className={this.state.menuClass1}></div>
                <div className={this.state.menuClass2}></div>
                <div className={this.state.menuClass3}></div>
              </div>
            </div>

          </div>
          {/*首图*/}
          <div className='top-top'>
            <Carousel fade={true} autoplay dots={false} autoplaySpeed={4000} speed={2000}>

                <img alt='img' className='top-top-img' src='./img/room/room5.jpg'></img>

                <img alt='img' className='top-top-img' src='./img/room/room6.jpg'></img>

                <img alt='img' className='top-top-img' src='./img/room/room7.jpg'></img>

            </Carousel>
            <div className='top-top-title'>
              <div className='top-top-title-1'>
                杏(Ann) Ginza Club
              </div>
              <br/>
              <div className='top-top-title-2'>
              大切なゲストのご接待には、<br/>
              周囲を気にせずゆっくりお過ごしいただけるお席をご用意いたします。
              </div>
            </div>
          </div>
          {/*SPACE*/}
          <div className='top-guide'>
            <div className='top-guide-box'>
              <div className='top-guide-img'>
                <img alt='img' className='top-guide-img-1' src='./img/room/top.jpeg'></img>
              </div>
              <div className='top-guide-text'>
                <div className='top-guide-text-title'>
                  - SPACE -
                </div>
                <div className='top-guide-text-content-1'>
                  空間
                </div>
                <div className='top-guide-text-content-2'>
                  Annクラブでは、優雅で落ち着いた空間がもたらす大人の癒しのひとときをできるだけ多くのお客様に楽しんでいただくため、銀座の高級クラブの相場よりなるべく抑えた料金で頑張らせていただきます。<br/><br/>
                  美しさと愛嬌を兼ね備えた女性キャストが、大切なゲストのご接待やプライベートの寛ぎの時間を精一杯お手伝いさせていただきます。
                </div>
              </div>
            </div>
            <div style={{'clear':'both'}}></div>
          </div>
          {/*室内*/}
          <a name="room"></a>
          <div className='top-room'>
            <div className='top-room-title'>
              - ROOM -
            </div>
            <div className='top-room-content-1'>
                  部屋
                </div>
            <div className='top-room-content-2'>
              高級クラブでの極上のひとときをお届けします。<br/>
              お一人様や少人数でしっとりと会話を楽しみたいとき、お仲間同士で盛り上がりたいときなど、皆さまそれぞれの癒しの時間をお手伝いさせていただきます。
            </div>
            <div className='top-room-imgs'>
              <img className='top-room-img' src='./img/room/room10.jpg'></img>
              <img className='top-room-img' src='./img/room/room8.jpg'></img>
              <img className='top-room-img' src='./img/room/room5.jpg'></img>
              <img className='top-room-img' src='./img/room/room7.jpg'></img>
              <div style={{'clear':'both'}}></div>
            </div>
          </div>
          {/*CAST*/}
          <a name="cast"></a>
          <div className='top-cast'>
            <div className='top-cast-box'>
              <div className='top-cast-title'>
                - CAST -
              </div>
              <div className='top-cast-content-1'>
                キャスト
              </div>
              <div className='top-cast-content-2'>
                可愛さ、色っぽさ、セクシーさなど、魅力をもった美しい女性達が常時10名在籍。洗練された見た目の美しさだけでなく、親しみ深い愛嬌を持ち合わせた女の子たちが、お客様おひとりおひとりに心を込めておもてなしいたします。
              </div>
              <div className='top-cast-imgs'>
                <img className='top-cast-img' src='./img/cast/cast1.jpeg'></img>
                <img className='top-cast-img' src='./img/cast/cast2.jpeg'></img>
                <img className='top-cast-img' src='./img/cast/cast3.jpeg'></img>
                <div style={{'clear':'both'}}></div>
              </div>
            </div>
          </div>
          {/*SYSTEM*/}
          <a name="system"></a>
          <div className='top-charge'>
            <div className='top-charge-title'>
              - SYSTEM -
            </div>
            <div className='top-charge-content-1'>
              料金
            </div>
            {/*
            <div className='top-charge-content-2'>
              クレジットカード決済時は10%頂戴いたします
            </div>
            */}
            <div className='top-charge-table'>
              <div className='top-charge-table-title'>
                <div className='top-charge-table-left'>
                  セット料金
                </div>
                <div className='top-charge-table-right'>
                  ￥15,000
                </div>
              </div>
              <div className='top-charge-table-list'>
                <div className='top-charge-table-left'>
                  指名料
                </div>
                <div className='top-charge-table-right'>
                  ￥5,000
                </div>
              </div>
              <div className='top-charge-table-list'>
                <div className='top-charge-table-left'>
                  同伴料
                </div>
                <div className='top-charge-table-right'>
                  ￥5,000
                </div>
              </div>
              <div className='top-charge-table-list'>
                <div className='top-charge-table-left'>
                  サービス料
                </div>
                <div className='top-charge-table-right'>
                  10%
                </div>
              </div>
              <div className='top-charge-table-list'>
                <div className='top-charge-table-left'>
                  TAX
                </div>
                <div className='top-charge-table-right'>
                  10%
                </div>
              </div>
            </div>
            <div className='top-charge-content-2'>
              注（1）セット料金につきまして、制限時間はありません。<br/>
              (2)ホームページ経由で2名以上初めてご来店のお客様限定ワインまたシャンパンを1本プレゼント致します。
            </div>
          </div>
          {/*WANTED*/}
          <div className='top-wanted'>
            <div className='top-wanted-title'>
              - WANTED -
            </div>
            <div className='top-wanted-content'>
              キャスト大募集
            </div>
            <div className='top-wanted-box'>
              私と一緒に楽しく働きませんか？？？<br/>
              ①入店体験可<br/>
              ②勤務中ドレス、ワンピース指定<br/>
              ③勤務時間相談可<br/>
              ④未経験可<br/>
              可愛くて、綺麗なあなたのご連絡をお待ちしております。<br/>
              <br/>
              <div className='line'></div>
              <div className='top-wanted-content'>
              クラブスタッフ急募
              </div>
              応募職種:店長・一般スタッフ<br/>
              勤務地:銀座<br/>
              勤務時間:19時〜<br/>
              報酬:店長 40万円〜<br/>
              一般スタッフ 日給或は時間給<br/>
              ※店舗売上により奨励金が支給します。<br/>
              ※無経験者も大歓迎<br/>
              応募をお待ちしています。
            </div>
          </div>
          {/*INFORMATION*/}
          <a name="information"></a>
          <div className='top-information'>
            <div className='top-information-title'>
              - INFORMATION -
            </div>
            <div className='top-information-content'>
              営業時間・アクセス
            </div>
            <div className='top-information-box'>
              <div className='top-information-left'>
                &lt;営業時間&gt; 19：00～25：00<br/>
                &lt;アクセス&gt; 東京都中央区銀座8-6-5 （ジーエスエイトビルディング1F）<br/>
                &lt;TEL&gt; 070-3293-1107<br/>
                &lt;TikTok&gt; lixing1107<br/>
                <br/>
                <iframe className='top-information-right-map' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3241.3313209869134!2d139.7584551757872!3d35.668843072591265!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60188be8e9656eb5%3A0x1e575b84faaf9f34!2sGs8%20Bldg%2C%208-ch%C5%8Dme-6-5%20Ginza%2C%20Chuo%20City%2C%20Tokyo%20104-0061!5e0!3m2!1szh-CN!2sjp!4v1728180482227!5m2!1szh-CN!2sjp" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
              </div>
              <div className='top-information-right'>
                &lt;LINE QRコード&gt; <br/>
                <img className='top-information-left-img' src='./img/code_line.png'></img>
                <br/>
                &lt;WECHAT QRコード&gt; <br/>
                <img className='top-information-left-img' src='./img/code_wechat.png'></img>
              </div>
              <div style={{'clear':'both'}}></div>
            </div>
          </div>
          {/*FOOT*/}
          <div className='top-foot'>
            <div className='top-foot-content'>
              <div className='top-foot-content-title'>
                杏(Ann) Ginza Club
              </div>
              <div className='top-foot-content-detail'>
                〒104-0061　東京都中央区銀座8-6-5 （ジーエスエイトビルディング1F）<br/>
                TEL : 070-3293-1107 / 営業時間 : 19:00～25:00
              </div>
              <div className='top-foot-content-rr'>
                @Annclub rights reserved.
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
  
  export default home;
  